import service from '@/utils/request'; // @Tags PubProduct
// @Summary 创建PubProduct
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PubProduct true "创建PubProduct"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /pubProduct/createPubProduct [post]

export var createPubProduct = function createPubProduct(data) {
  return service({
    url: "/pubProduct/createPubProduct",
    method: 'post',
    data: data
  });
}; // @Tags PubProduct
// @Summary 删除PubProduct
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PubProduct true "删除PubProduct"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /pubProduct/deletePubProduct [delete]

export var deletePubProduct = function deletePubProduct(data) {
  return service({
    url: "/pubProduct/deletePubProduct",
    method: 'delete',
    data: data
  });
}; // @Tags PubProduct
// @Summary 删除PubProduct
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除PubProduct"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /pubProduct/deletePubProduct [delete]

export var deletePubProductByIds = function deletePubProductByIds(data) {
  return service({
    url: "/pubProduct/deletePubProductByIds",
    method: 'delete',
    data: data
  });
}; // @Tags PubProduct
// @Summary 更新PubProduct
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PubProduct true "更新PubProduct"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /pubProduct/updatePubProduct [put]

export var updatePubProduct = function updatePubProduct(data) {
  return service({
    url: "/pubProduct/updatePubProduct",
    method: 'put',
    data: data
  });
}; // @Tags PubProduct
// @Summary 用id查询PubProduct
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PubProduct true "用id查询PubProduct"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /pubProduct/findPubProduct [get]

export var findPubProduct = function findPubProduct(params) {
  return service({
    url: "/pubProduct/findPubProduct",
    method: 'get',
    params: params
  });
}; // @Tags PubProduct
// @Summary 分页获取PubProduct列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取PubProduct列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /pubProduct/getPubProductList [get]

export var getPubProductList = function getPubProductList(params) {
  return service({
    url: "/pubProduct/getPubProductList",
    method: 'get',
    params: params
  });
};