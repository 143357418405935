var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品搜索" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "ID/名称" },
                    model: {
                      value: _vm.searchInfo.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "keyword", $$v)
                      },
                      expression: "searchInfo.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "计划类型" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择状态" },
                          model: {
                            value: _vm.searchInfo.type,
                            callback: function($$v) {
                              _vm.$set(_vm.searchInfo, "type", $$v)
                            },
                            expression: "searchInfo.type"
                          }
                        },
                        _vm._l(
                          [
                            { value: 0, label: "全部" },
                            { value: 1, label: "普通" },
                            { value: 2, label: "定向" }
                          ],
                          function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }
                        ),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openDialog }
                    },
                    [_vm._v("添加商品")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { label: "商品ID", prop: "pubProductId", width: "180" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品主图",
              prop: "pubProduct.cover",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: {
                        src: scope.row.pubProduct.cover,
                        "preview-src-list": [scope.row.pubProduct.cover]
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "商品标题", prop: "pubProduct.name" }
          }),
          _c("el-table-column", {
            attrs: { label: "售价", prop: "pubProduct.price", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v("¥" + _vm._s(scope.row.pubProduct.price))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "佣金比率", prop: "rate", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.rate) + "%")])]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "添加推广商品",
            visible: _vm.drawer,
            direction: _vm.direction,
            size: "45%",
            "custom-class": "tui-plan-goods-add"
          },
          on: {
            "update:visible": function($event) {
              _vm.drawer = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  ref: "productsTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.products,
                    border: "",
                    stripe: "",
                    "tooltip-effect": "dark"
                  },
                  on: { "selection-change": _vm.handleProductSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品标题", prop: "cover" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-image", {
                              staticStyle: { width: "48px", height: "48px" },
                              attrs: {
                                src: scope.row.cover,
                                "preview-src-list": [scope.row.cover]
                              }
                            }),
                            scope.row.promotion
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "activity-list-table-name-wrap__tip"
                                  },
                                  [_vm._v(" 推广中")]
                                )
                              : _vm._e(),
                            _c("span", { staticStyle: { float: "right" } }, [
                              _vm._v(_vm._s(scope.row.name))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品ID", prop: "thirdProductId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "售价", prop: "price" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v("¥" + _vm._s(scope.row.price))])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("el-pagination", {
                style: { float: "right", padding: "20px" },
                attrs: {
                  "current-page": _vm.page,
                  "page-size": _vm.pageSize,
                  "page-sizes": [10, 30, 50, 100],
                  total: _vm.productTotal,
                  layout: "total, sizes, prev, pager, next, jumper"
                },
                on: {
                  "current-change": _vm.handleProductCurrentChange,
                  "size-change": _vm.handleProductSizeChange
                }
              }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: {
                    position: "fixed",
                    bottom: "0",
                    width: "45%",
                    background: "white",
                    "z-index": "99"
                  },
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入佣金" },
                              model: {
                                value: _vm.planForm.rate,
                                callback: function($$v) {
                                  _vm.$set(_vm.planForm, "rate", $$v)
                                },
                                expression: "planForm.rate"
                              }
                            },
                            [_c("template", { slot: "append" }, [_vm._v("%")])],
                            2
                          )
                        ],
                        1
                      ),
                      _c("el-button", { on: { click: _vm.closeDialog } }, [
                        _vm._v("取 消")
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.planSubmit }
                        },
                        [_vm._v("确 定")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }